@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --chart-1: 359 2% 90%;
    --chart-2: 240 1% 74%;
    --chart-3: 240 1% 58%;
    --chart-4: 240 1% 42%;
    --chart-5: 240 2% 26%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
    --chart-1: 359 2% 90%;
    --chart-2: 240 1% 74%;
    --chart-3: 240 1% 58%;
    --chart-4: 240 1% 42%;
    --chart-5: 240 2% 26%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/NeueMontreal-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
    /* @apply font-nunito antialiased bg-background text-foreground; */
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.arrow {
  position: relative;
}

.arrow::after {
  content: "";
  position: absolute;
  top: 50%; /* Adjust to align vertically */
  left: 100%; /* Position after the image */
  transform: translateY(-50%);
  width: 30px;
  height: 10px;
  background-image: url("./assets/landingpage/Arrow-1.svg"); /* Replace with your SVG path */
  background-size: contain;
  background-repeat: no-repeat;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;
}

.SliderTrack {
  background-color: var(--chart-1);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
}

.SliderRange {
  position: absolute;
  background-color: #000;
  border-radius: 9999px;
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 20px;
  height: 20px;
  margin-bottom: 20px;
  /* background-color: #000; */
  box-shadow: 0 2px 10px var(--black-a7);
  /* border-radius: 10px; */
}
.SliderThumb:hover {
  cursor: pointer;
  /* background-color: var(--violet-3); */
}
.SliderThumb:focus {
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 0 5px var(--black-a8);
}

.liquidity-planner-app .disabled {
  color: #666;
  background-color: #8181810e;
}

.liquidity-planner-app .reactgrid-content .rg-pane .rg-cell {
  padding: 8px 16px;
  font-size: 12px;
}

.liquidity-planner-app .padding-left-lg {
  padding-left: 24px !important;
}

.liquidity-planner-app .justify-content-center {
  justify-content: center;
}

.liquidity-planner-app .justify-content-end {
  justify-content: flex-end;
}

.liquidity-planner-app .align-items-end {
  align-items: flex-end !important;
}

.liquidity-planner-app .font-bold {
  font-weight: 600;
}

.liquidity-planner-app .text-red {
  color: #cf1322;
}

.liquidity-planner-app .text-blue {
  color: #2797ff;
}

.liquidity-planner-app .text-green {
  color: #107c41;
}

.liquidity-planner-app .light-green-bg {
  background-color: #e7f2ec;
}

.liquidity-planner-app .text-md {
  font-size: 14px !important;
}

.liquidity-planner-app .text-lg {
  font-size: 16px !important;
}

.liquidity-planner-app .rg-celleditor {
  font-size: 12px;
  padding: 8px 16px;
  border-width: 1px;
  box-shadow: 0px 0px 8px #2797ff;
}

.liquidity-planner-app .rg-celleditor input {
  font-family: "DM Sans", sans-serif;
}

.liquidity-planner-app .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}

.liquidity-planner-app .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}
